<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">ACCESS MODULE</h3>
      <el-button type="primary" style="margin-left: 1rem;" @click="dialogAccessModule = true">Add New Access Module</el-button>
    </div>
    <el-dialog title="Add Access Module" :visible.sync="dialogAccessModule">
      <el-form ref="accessmoduleForm" :model="accessmoduleForm" label-width="130px">
        <el-form-item label="Access Module Name">
          <el-input v-model="accessmoduleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAccessModule = false">Cancel</el-button>
        <el-button type="primary" @click="addAccessModule">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Access Module" :visible.sync="editAccessModuleDialog">
      <el-form ref="editaccessmoduleForm" :model="editaccessmoduleForm" label-width="130px">
        <el-form-item label="Access Module Name">
          <el-input v-model="editaccessmoduleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAccessModuleDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateAccessModule">Confirm</el-button>
      </span>
    </el-dialog>
    <el-table :data="data" border max-height="580">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="NAME" prop="name"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" align="center" width="100">
        <template slot-scope="props">
          <el-button-group>
            <el-button type="success" icon="el-icon-edit" size="mini" @click="editAccessModule(props.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'AccessModule',
  data () {
    return {
      data: [],
      dialogAccessModule: false,
      editAccessModuleDialog: false,
      accessmoduleForm: {
        name: null
      },
      editaccessmoduleForm: {
        id: null,
        name: null
      }
    }
  },
  mounted () {
    this.getAccessModule()
  },
  methods: {
    getAccessModule () {
      this.$http
        .get('sysadmin.systemaccess/module-list')
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body : []
        })
    },
    addAccessModule () {
      this.dialogAccessModule = false
      this.$http
        .post('sysadmin.systemaccess/module', this.accessmoduleForm)
        .then(() => {
          this.getAccessModule()
          this.$message({
            message: 'New Access Module Added',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    editAccessModule (row) {
      this.editAccessModuleDialog = true
      this.editaccessmoduleForm.id = row.id
      this.editaccessmoduleForm.name = row.name
    },
    updateAccessModule () {
      this.editAccessModuleDialog = false
      this.$http
        .post('sysadmin.systemaccess/module-update', this.editaccessmoduleForm)
        .then(() => {
          this.getAccessModule()
          this.$message({
            message: 'Access Module Updated',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-button {
    margin-right: 1rem;
  }
</style>
